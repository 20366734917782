import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import { shopModule } from "@/store/shop-module.js";
// const dataState = createPersistedState({
//   paths: ["shop"],
// });
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    shop: shopModule,
  },
  // plugins: [dataState],
  state: {
    site: [],
    home: [],
    people: [],
    product: [],
    products: [],
    about: [],
    policies: [],
    terms: [],
    showCookieWarning: true,
  },
  getters: {
    getPersonByUID: (state) => (uid, collection = state.people) => {
      let i = collection.length;
      while (i--) {
        if (collection[i].uid === uid) {
          return collection[i];
        }
      }
    },
  },
  actions: {
    async loadSite({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "site",
            select: {
              title: true,
              url: true,
              subscriptiontext: "site.subscriptiontext.kirbytext",
              subscriptionconfirm: "site.subscriptionconfirm.kirbytext",
              current: {
                query: "site.currentissue.toPage",
                select: {
                  title: true,
                  id: "page.children.listed.first.stripeid",
                  price: "page.children.listed.first.price",
                  weight: "page.children.listed.first.weight",
                },
              },
              about: {
                query: "page('about')",
                select: {
                  general: "page.text.kirbytext",
                  address: "page.address.kirbytext",
                  email: "page.email",
                },
              },
              categories: "site.page('products').cats.split(',')",
            },
          },
          {
            auth: {
              username: process.env.VUE_APP_USERNAME,
              password: process.env.VUE_APP_PASSWORD,
            },
          }
        )
        .then(function (response) {
          commit("SET_SITE", response.data.result);
        });
    },
    async loadHome({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('home').contents.toStructure",
            select: {
              title: "structureItem.title.kirbytext",
              type: "structureItem.type",
              color: "structureItem.color.value",
              size: "structureItem.size.value",
              external: "structureItem.external",
              internal: {
                query: "structureItem.internal.toPage",
                select: ["title", "uri"],
              },
              image: {
                query: "structureItem.image.toFile",
                select: {
                  url: "file.resize(1200).url",
                  thumb: "file.resize(200).url",
                },
              },
              video: {
                query: "structureItem.video.toFile",
                select: {
                  url: "file.url",
                },
              },
            },
          },
          {
            auth: {
              username: process.env.VUE_APP_USERNAME,
              password: process.env.VUE_APP_PASSWORD,
            },
          }
        )
        .then(function (response) {
          commit("SET_HOME", response.data.result);
        });
    },
    async loadPeople({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('people').children.listed.shuffle",
            select: {
              title: true,
              uid: true,
            },
          },
          {
            auth: {
              username: process.env.VUE_APP_USERNAME,
              password: process.env.VUE_APP_PASSWORD,
            },
          }
        )
        .then(function (response) {
          commit("SET_PEOPLE", response.data.result);
        });
    },
    async loadProducts({ commit }, payload) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('products')",
            select: {
              products: {
                query: "page.children.listed",
                select: {
                  title: true,
                  uid: true,
                  num: true,
                  caption: true,
                  category: "page.category",
                  catalognumber: "page.catalognr",
                  displayas: "page.displayas",
                  vinylcolor: "page.vinylcolor.value",
                  cassettecolor: "page.cassettecolor.value",
                  bandcamp: "page.bandcamp.value",
                  bandcampvinyl: "page.bandcampvinyl.value",
                  streaming: {
                    query: "page.streaming.toStructure",
                    select: {
                      name: "structureItem.name",
                      url: "structureItem.url",
                    },
                  },
                  artist: {
                    query: "page.artist.toPages",
                    select: {
                      title: "page.title",
                      uid: "page.uid",
                    },
                  },
                  subscription: "page.subscribe.toBool",
                  items: {
                    query: "page.children.listed",
                    select: {
                      stripeid: "page.stripeid",
                      id: "page.uid",
                      price: true,
                      stock: true,
                      weight: true,
                    },
                  },
                  images: {
                    query: "page.images.sortBy('sort').limit(2)",
                    select: {
                      thumb: "file.resize(100).url",
                      url: "file.resize(600).url",
                    },
                  },
                },
                pagination: {
                  page: payload,
                  limit: 20,
                },
              },
            },
          },
          {
            auth: {
              username: process.env.VUE_APP_USERNAME,
              password: process.env.VUE_APP_PASSWORD,
            },
          }
        )
        .then(function (response) {
          commit("SET_PRODUCTS", response.data.result.products);
        });
    },
    async loadProduct({ commit }, payload) {
      await axios
        .post(
          "/api/query",
          {
            query: `page('products').children.find('${payload}')`,
            select: {
              title: true,
              uid: true,
              num: true,
              caption: true,
              category: "page.category",
              catalognumber: "page.catalognr",
              displayas: "page.displayas",
              vinylcolor: "page.vinylcolor.value",
              cassettecolor: "page.cassettecolor.value",
              bandcamp: "page.bandcamp.value",
              bandcampvinyl: "page.bandcampvinyl.value",
              streaming: {
                query: "page.streaming.toStructure",
                select: {
                  name: "structureItem.name",
                  url: "structureItem.url",
                },
              },
              artist: {
                query: "page.artist.toPages",
                select: {
                  title: "page.title",
                  uid: "page.uid",
                },
              },
              subscription: "page.subscribe.toBool",
              description: "page.description.kirbytext",
              seo: "page.description.short(150)",
              seoimage: "page.images.first.resize(1080).url",
              metadatastructure: {
                query: "page.metadatastructure.toStructure",
                select: {
                  key: "structureItem.key",
                  name: "structureItem.name",
                },
              },
              items: {
                query: "page.children.listed",
                select: {
                  stripeid: "page.stripeid",
                  id: "page.uid",
                  price: true,
                  stock: true,
                  weight: true,
                },
              },
              images: {
                query: "page.images.sortBy('sort').limit(12)",
                select: {
                  thumb: "file.resize(100).url",
                  url: "file.resize(1200).url",
                },
              },
            },
          },
          {
            auth: {
              username: process.env.VUE_APP_USERNAME,
              password: process.env.VUE_APP_PASSWORD,
            },
          }
        )
        .then(function (response) {
          commit("SET_PRODUCT", response.data.result);
        });
    },
    async loadAbout({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('about')",
            select: {
              title: true,
              uid: true,
              seotext: "page.seodescription.excerpt('155')",
              text: "page.text.kirbytext",
              description: "page.description.kirbytext",
              impressum: "page.impressum.kirbytext",
              address: "page.address.kirbytext",
              email: "page.email",
              channels: {
                query: "site.page('channels').channels.toStructure",
                select: {
                  text: "structureItem.text.kirbytext",
                  link: "structureItem.link",
                  color: "structureItem.color.value",
                },
              },
            },
          },
          {
            auth: {
              username: process.env.VUE_APP_USERNAME,
              password: process.env.VUE_APP_PASSWORD,
            },
          }
        )
        .then(function (response) {
          commit("SET_ABOUT", response.data.result);
        });
    },
    async loadPolicies({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('policies')",
            select: {
              title: true,
              uid: true,
              text: "page.privacy.kirbytext",
            },
          },
          {
            auth: {
              username: process.env.VUE_APP_USERNAME,
              password: process.env.VUE_APP_PASSWORD,
            },
          }
        )
        .then(function (response) {
          commit("SET_POLICIES", response.data.result);
        });
    },
    async loadTerms({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('terms')",
            select: {
              title: true,
              uid: true,
              text: "page.termsconditions.kirbytext",
            },
          },
          {
            auth: {
              username: process.env.VUE_APP_USERNAME,
              password: process.env.VUE_APP_PASSWORD,
            },
          }
        )
        .then(function (response) {
          commit("SET_TERMS", response.data.result);
        });
    },
  },
  mutations: {
    SET_SITE(state, site) {
      state.site = site;
    },
    SET_HOME(state, pages) {
      state.home = pages;
    },
    SET_PEOPLE(state, pages) {
      state.people = pages;
    },
    SET_PRODUCTS(state, pages) {
      if (state.products.pagination) {
        state.products.pagination = pages.pagination;
        for (var i = 0; i < pages.data.length; i++) {
          state.products.data.push(pages.data[i]);
        }
      } else {
        state.products = pages;
      }
    },
    SET_PRODUCT(state, pages) {
      state.product = pages;
    },
    SET_ABOUT(state, pages) {
      state.about = pages;
    },
    SET_POLICIES(state, pages) {
      state.policies = pages;
    },
    SET_TERMS(state, pages) {
      state.terms = pages;
    },
  },
});
